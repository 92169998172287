import Loader from "components/shared/Loader";
import dangerModalConfig from "./config/dangerModalConfig";
import React, { useState } from "react";
import { dangerModalProps } from "./domain/types";
import capitalizeFirstLetter from "utilities/capitalize";

const DangerModal: React.FC<dangerModalProps> = ({
  page,
  selectedTag,
  setSelectedTag,
  refresh,
  type
}) => {
  const pageConfig = dangerModalConfig[page];
  const { title } = pageConfig;
  const { action } = pageConfig[type];
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);

  return (
    <div className="w-full h-full bg-dark p-4 rounded-lg">
      <div className="flex flex-col gap-5 z-50">
        <div className="flex justify-center items-center flex-col gap-10 p-3 text-center">
          <p>
            Are you sure you want to {type === 'deleteAll' ? 'remove all' : `${selectedTag.action} this ${title}`}?
          </p>
          {isLoadingProcess && <Loader />}
          <div className="flex gap-5">
            <button className="btn-danger" onClick={async () => {
              if (action) {
                try {
                  setIsLoadingProcess(true);
                  await action(selectedTag.value?.id);
                  await refresh();
                  setIsLoadingProcess(false);
                  setSelectedTag({ action: "", value: null });
                } catch (e) {
                  console.error(e);
                }
              }
            }}>
              {type === 'deleteAll' ? 'remove all' : capitalizeFirstLetter(selectedTag.action)}
            </button>
            <button
              className="btn-sec"
              onClick={() => {
                setSelectedTag({ action: "", value: null });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DangerModal;